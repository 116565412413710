import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LogOut } from 'angular-feather/icons';
import { CategoryService } from 'src/app/service/category.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';
import { UsersService } from 'src/app/service/users.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';


const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();
const date = today.getDate();

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent {
  displayedColumns: string[] = [
    'productCount',
    'username',
    'address',
    'phone',
    'productDetails',
    'image',
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  environmentURL = environment.apiUrl;
  spinner: boolean = false;
  userData: any;
  categoryWiseProducts: any;
  categoryId!: string;
  

  constructor(
    private userService: UsersService,
    private categoryService: CategoryService,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Leaderboard');
    this.getAllCategories()
    this.campaignOne.valueChanges.subscribe(value => {
      if (value.start && value.end) {
        this.generateReport(this.categoryId);
      }
    });
  }

  
  readonly campaignOne = new FormGroup({
    start: new FormControl(new Date(year, month, date - 30)),
    end: new FormControl(new Date(year, month, date)),
  });

  getAllCategories() {
    this.spinner = true;
    this.categoryService.getAllCategories().subscribe({
      next: async (categories) => {
        this.categoryWiseProducts = categories.categories;
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Categories',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  selectCategory(event: string) {
    this.categoryId = event
    this.generateReport(this.categoryId);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

 
  generateReport(categoryId: string) {
    const campaignValues = this.campaignOne.value;
    const startDateISO = campaignValues.start ? (campaignValues.start as Date).toISOString() : null;
    const endDateISO = campaignValues.end ? (campaignValues.end as Date).toISOString() : null;
    let data = {
      categoryID: categoryId,
      startDate: startDateISO,
      endDate: endDateISO
    }
    this.userService.generateReport(data).subscribe({
      next: async (report) => {
        this.dataSource = await new MatTableDataSource(report.report);
        this.userData = report.report;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Users',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  convertUtcToIst(utcDate: string | number | Date): string {
    // Create a Date object from the UTC date input
    const date = new Date(utcDate);

    // Correct options for DateTimeFormatOptions
    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',       // "numeric" or "2-digit"
        month: '2-digit',      // "numeric" or "2-digit"
        day: '2-digit',        // "numeric" or "2-digit"
        hour: '2-digit',       // "numeric" or "2-digit"
        minute: '2-digit',     // "numeric" or "2-digit"
        second: '2-digit'      // "numeric" or "2-digit"
    };

    // Convert to localized string based on the options
    return date.toLocaleString('en-IN', options);
}

  exportToExcel(): void {
    // Map through userData and format each date
    const data = this.userData.map(({ _id, password, categoryID, productId, OTP, is2FA, sessionToken, isNumberVerified, __v, registeredDate, ...rest }: any) => ({
      ...rest,
      registeredDate: registeredDate ? this.convertUtcToIst(registeredDate) : 'N/A'
    }));

    // Create a worksheet from JSON data
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Test');

    // Define headers name
    XLSX.utils.sheet_add_aoa(ws, [["Number of Products", "User Name", "Phone", "State", "City", "Pincode", "Company Name", "User Unique ID", "Product Name", "Registered Date"]], { origin: "A1" });

    // Write the workbook to a file
    XLSX.writeFile(wb, 'leaderboard.xlsx');
  }



  // onDelete(id: string) {
  //   this.userService.deleteUser(id).subscribe({
  //     next: (data) => {
  //       this.getAllUsers();
  //     },
  //     error: (error) => {
  //       this.toastr.error(
  //         error.message ? error.message : 'Could not delete user',
  //         'Error'
  //       );
  //     },
  //     complete: () => { },
  //   });
  // }

  onEdit(id: string) {
    this.router.navigate(['/editUser/', id]);
  }
}
